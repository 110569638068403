var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "green-border-box in-padd white-bg" },
    [
      _c("CouponForm", {
        attrs: {
          brandId: _vm.brandId,
          couponDetails: _vm.coupon,
          editCouponFlag: true
        },
        on: {
          redirectToOffers: function($event) {
            return _vm.$router.push("/brand/coupons")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }